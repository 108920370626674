import React from 'react';
import Layout from '../../components/layout/layout';

const previewImage = {imageFile: 'de.png', imageWidth: 1200, imageHeight: 630, imageDescription: 'Screenshot of the website'};

export default function PageContent(props) {
  let language = 'de';
  return (
    <Layout language={language} title="Home in Deutsch" description="Manuel auf Deutsch" path={props.path} previewimage={previewImage} hasLanguageCounterpart={true}>
      <div className="pageHeroContainer">
        <img className="pageHeroImage" src="/images/code_on_screen.jpg" alt="Some Angular Code" />
      </div>
      <h1>Programmieren, Web, Multimedia.</h1>
      <h3>Drei Schlüsselwörter die meine Interessen beschreiben, was seit ca. 1995 meine Leidenschaft ist.</h3>
      <p>
        Ich bin mit analogen Fotos und Dias, VHS und Hi8 Video Cameras sowie Commodore 64 und Amiga 500 / 2000 aufgewachsen.
        Als Teenager habe ich mich dann mit IBM kompatiblen Computern beschäftigt und während meiner Lehre zum Industrie-Elektroniker (Fachrichtung Gerätetechnik) bin ich dann mit SPS und Mikrokontrollern in Kontakt gekommen.
      </p>
      <p>
        Mit der immer weiter fortschreitenden Technologie und den Anfängen des World Wide Web habe ich begonnen mit mehr Dingen zu herumzuspielen, wobei ich dann um 2005 angefangen habe Software-Entwicklung zu meinem Beruf zu machen - während ich mit dem Aufkommen des Internet of Things dann auch wieder etwas mehr mit Eletronik gearbeitet habe.
      </p>
      <p>
        Neben der Software-Entwicklung beschäftige ich mich in meiner Freizeit mit digitalen Medien wie Fotografie, Video und Animation. Wenn Du mehr darüber wissen möchtest oder einfach nur Interesse an dem hast was ich gemacht habe und noch tue kannst Du Dich her umschauen. Viel Spaß dabei...
      </p>

    </Layout>
  )
}
